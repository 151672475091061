export default class Config {
    // static BASE_URL = 'localhost:8000'
    // static WSGI_PROTOCOL = 'http'
    // static ASGI_PROTOCOL = 'ws'
    static IDLE_TIME = 300000
    static LOGOUT_TIME = 600000
    // static BASE_URL = 'registracia.omniumcapital.sk'
    // static WSGI_PROTOCOL = 'https'
    // static ASGI_PROTOCOL = 'wss'

    static BASE_URL = 'portal.omniumcapital.sk'
    static WSGI_PROTOCOL = 'https'
    static ASGI_PROTOCOL = 'wss'
}